import { Color, ScaleType } from '@swimlane/ngx-charts';

export const colorScheme: Color = {
  name: 'primary',
  selectable: true,
  group: ScaleType.Time,
  domain: ['#424c65', '#18213a', '#405691', '#31416d']
}

export const secondaryColorScheme: Color = {
  name: 'secondary',
  selectable: true,
  group: ScaleType.Linear,
  domain: ['green', 'red']
  //domain: ['#424c65', '#18213a', '#405691', '#31416d']
}
