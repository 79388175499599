import { LineChartComponent, ScaleType } from '@swimlane/ngx-charts';

import { colorScheme } from './graph.constants';


export const RequestBarGraph: Partial<LineChartComponent> = {
  animations: true,
  xAxis: true,
  yAxis: true,
  showYAxisLabel: true,
  showXAxisLabel: true,
  yAxisLabel: 'Classifications',
  xAxisLabel: 'Time',
  timeline: false,
  legend: true,
  // curve: curveStep,
  scheme: colorScheme
}

export const ResultsBarGraph: Partial<LineChartComponent> = {
  animations: true,
  xAxis: true,
  yAxis: true,
  showYAxisLabel: true,
  showXAxisLabel: true,
  yAxisLabel: 'Confidence %',
  xAxisLabel: 'Model',
  timeline: false,
  autoScale: false,
  yScaleMax: 100,
  yScaleMin: 0,
  legend: true,
  schemeType: ScaleType.Linear,
  // curve: curveStep,
  scheme: colorScheme
  // scheme: secondaryColorScheme
}
