import { Component, Inject, OnInit, Optional } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { Request } from "src/app/commons/models/request.model";
import { AlertService } from "src/app/commons/services/alert.service";
import { MAX_SIZE, VALID_EXTENSIONS } from "src/app/constants/media.constants";
import {
  fileExtensionValidator,
  fileSizeValidator,
} from "src/app/helpers/validators/file.validator";
import * as RequestActions from "src/app/store/actions/request.actions";
import { AppState } from "src/app/store/reducers";
import * as AuthSelectors from "src/app/store/selectors/auth.selectors";

@Component({
  selector: "identifai-request-send",
  templateUrl: "./request-send.component.html",
  styleUrls: ["./request-send.component.scss"],
})
export class RequestSendComponent {
  private unsubscribe$ = new Subject<void>();

  imageCtrl: UntypedFormControl = new UntypedFormControl(null, [
    Validators.required, fileExtensionValidator(VALID_EXTENSIONS), fileSizeValidator(MAX_SIZE)
  ]);

  maxSize = MAX_SIZE;
  acceptsString: string = VALID_EXTENSIONS.map((v) => `.${v}`).join(',')

  constructor(
    private store$: Store<AppState>,
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  send() {
    let image = this.imageCtrl.value;
    this.store$.dispatch(RequestActions.sendRequest({ image }));
  }

  close() {
    this.store$.dispatch(RequestActions.closeRequestDialog());
  }
}
