import { createAction, props } from '@ngrx/store';

import { User } from '../../commons/models/user.model';
import { Credit, CreditDTO, CreditFilters, CreditGraphData } from 'src/app/commons/models/credit.model';

export const showBalance = createAction('[Credit] Show balance', props<{ user?: User }>());

export const loadCredits = createAction('[Credits] Load credits', props<{ page?: number, perPage?: number, order?: string, direction?: string, filters?: CreditFilters, includes?: string[] }>());
export const loadCreditsCompleted = createAction('[Credits] Load credits Completed', props<{ credits: CreditDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: CreditFilters, includes?: string[] }>());
export const loadCreditsFailed = createAction('[Credits] Load credits Failed', props<{ error: any }>());

export const loadCreditsGraph = createAction('[Credits] Load credits graph', props<{ userId?: number }>());
export const loadCreditsGraphCompleted = createAction('[Credits] Load credits graph Completed', props<{ result: CreditGraphData }>());
export const loadCreditsGraphFailed = createAction('[Credits] Load credits graph Failed', props<{ error: any }>());

export const changePage = createAction('[Credits] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Credits] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Credits] Change filters', props<{ filters: CreditFilters }>());

export const editCredit = createAction('[Credit] Edit credit', props<{ credit?: Credit, user?: User }>());
export const creditDialogOpened = createAction('[Credit] Detail dialog opened', props<{ dialogId: string }>());
export const closeCreditDialog = createAction('[Credit] Close detail dialog');

export const saveCredit = createAction('[Credit] Save credit', props<{ credit: Credit }>());
export const saveCreditCompleted = createAction('[Credit] Save credit completed', props<{ credit: CreditDTO }>());
export const saveCreditFailed = createAction('[Credit] Save credit failed', props<{ error: any }>());

export const deleteCredit = createAction('[Credit] Delete credit', props<{ credit: CreditDTO }>());
export const deleteCreditCompleted = createAction('[Credit] Delete credit completed', props<{ credit: CreditDTO }>());
export const deleteCreditCancelled = createAction('[Credit] Delete credit cancelled');
export const deleteCreditFailed = createAction('[Credit] Delete credit failed', props<{ error: any }>());

export const requestCreditDialog = createAction('[Credit] Request credit dialog');
export const requestCreditDialogOpened = createAction('[Credit] Request dialog opened', props<{ dialogId: string }>());
export const closeRequestCreditDialog = createAction('[Credit] Close Credit request dialog');

export const requestCredit = createAction('[Credit] Request credit', props<{ amount: number }>());
export const requestCreditCompleted = createAction('[Credit] Request credit completed');
export const requestCreditFailed = createAction('[Credit] Request credit failed', props<{ error: any }>());

export const exportCredits = createAction('[Credits] Export credits', props<{ userId?: number }>());
export const exportCreditsCompleted = createAction('[Credits] Export credits Completed', props<{ data: string }>());
export const exportCreditsFailed = createAction('[Credits] Export credits Failed', props<{ error: any }>());