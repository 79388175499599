import { Component, Inject, OnDestroy, OnInit, Optional } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { Report } from "src/app/commons/models/report.model";
import { User } from "src/app/commons/models/user.model";
import { AlertService } from "src/app/commons/services/alert.service";
import { MAX_SIZE, VALID_EXTENSIONS } from "src/app/constants/media.constants";
import {
  fileExtensionValidator,
  fileSizeValidator,
} from "src/app/helpers/validators/file.validator";
import * as ReportActions from "src/app/store/actions/report.actions";
import { AppState } from "src/app/store/reducers";
import * as AuthSelectors from "src/app/store/selectors/auth.selectors";

@Component({
  selector: "identifai-image-report",
  templateUrl: "./image-report.component.html",
  styleUrls: ["./image-report.component.scss"],
})
export class ImageReportComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  reportForm: UntypedFormGroup = new UntypedFormGroup({
    image: new UntypedFormControl(null, [
      Validators.required, fileExtensionValidator(VALID_EXTENSIONS), fileSizeValidator(MAX_SIZE)
    ]),
    classification: new UntypedFormControl(null, [Validators.required]),
  });

  maxSize = MAX_SIZE;
  acceptsString: string = VALID_EXTENSIONS.map((v) => `.${v}`).join(',')

  currentUser: User;

  constructor(
    private store$: Store<AppState>,
  ) {
    this.store$.select(AuthSelectors.getCurrentUser).pipe(
      takeUntil(this.unsubscribe$),
      map(dto => dto ? new User(dto) : null),
    ).subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  send() {
    let { image, classification } = this.reportForm.value;
    this.store$.dispatch(ReportActions.sendReport({ image, classification }));
  }

  close() {
    this.store$.dispatch(ReportActions.closeReportDialog());
  }
}
