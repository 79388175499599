import { Action, createReducer, on } from '@ngrx/store';

import * as CreditActions from '../actions/credit.actions';
import { CreditFilters, Credit, CreditDTO, CreditGraphData } from 'src/app/commons/models/credit.model';

export interface CreditState {
  list: CreditDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: CreditFilters,
  dialogId: string,
  credit: Credit,
  requestDialogId: string,
  graphData: CreditGraphData,
};

const initialState: CreditState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  credit: null,
  requestDialogId: null,
  graphData: null,
};

const userReducer = createReducer(
  initialState,
  on(CreditActions.loadCreditsCompleted, (state, { credits, currentPage, total, perPage, order, direction, includes }): CreditState => {
    return { ...state, list: credits, currentPage, total, perPage, order, direction, includes };
  }),
  on(CreditActions.creditDialogOpened, (state, { dialogId }): CreditState => {
    return { ...state, dialogId };
  }),
  on(CreditActions.changeFilters, (state, { filters }): CreditState => {
    return { ...state, currentPage: 1, filters };
  }),
  on(CreditActions.showBalance, (state, { user }): CreditState => {
    return { ...state, filters: user ? { ...state.filters, user: user } : state.filters };
  }),
  on(CreditActions.requestCreditDialogOpened, (state, { dialogId }): CreditState => {
    return { ...state, requestDialogId: dialogId };
  }),
  on(CreditActions.loadCreditsGraphCompleted, (state, { result: graphData }): CreditState => {
    return { ...state, graphData };
  })
);

export function reducer(state: CreditState | undefined, action: Action) {
  return userReducer(state, action);
}

