import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClassificationResult, ClassificationType } from 'src/app/commons/models/image.model';
import { ResultsBarGraph } from 'src/app/constants/request.constants';
import { StackGraphBarData } from 'src/app/helpers/graph.utils';

@Component({
  selector: 'identifai-verdicts',
  templateUrl: './verdicts.component.html',
  styleUrls: ['./verdicts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerdictsComponent {

  @Input()
  results: ClassificationResult[];

  @Input()
  verdict: ClassificationType = 'human';

  chartConfig = ResultsBarGraph

  constructor() {

  }

  get data(): StackGraphBarData[] {
    return this.results.map(result => {
      const name = result.model;
      const right = result.classification.find(c => c.label === this.verdict);
      const wrong = result.classification.find(c => c.label !== this.verdict);

      return {
        name, series: [{ name: right.label, value: right.score * 100 }, { name: wrong.label, value: wrong.score * 100 }]
      }
    });
  }

}
