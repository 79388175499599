import { createAction, props } from '@ngrx/store';
import { Request, RequestDTO, RequestFilters, RequestsGraphData } from 'src/app/commons/models/request.model';
import { User } from 'src/app/commons/models/user.model';


export const loadRequests = createAction('[Requests] Load requests', props<{ page?: number, perPage?: number, order?: string, direction?: string, filters?: RequestFilters, includes?: string[] }>());
export const loadRequestsCompleted = createAction('[Requests] Load requests Completed', props<{ requests: RequestDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: RequestFilters, includes?: string[] }>());
export const loadRequestsFailed = createAction('[Requests] Load requests Failed', props<{ error: any }>());

export const loadRequestsGraph = createAction('[Requests] Load requests graph');
export const loadRequestsGraphCompleted = createAction('[Requests] Load requests graph Completed', props<{ result: RequestsGraphData }>());
export const loadRequestsGraphFailed = createAction('[Requests] Load requests graph Failed', props<{ error: any }>());

export const changePage = createAction('[Requests] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Requests] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Requests] Change filters', props<{ filters: RequestFilters }>());

export const openRequestDialog = createAction('[Requests] Opened request dialog');
export const requestDialogOpened = createAction('[Requests] Detail dialog opened', props<{ dialogId: string }>());
export const closeRequestDialog = createAction('[Requests] Close detail dialog');

export const showRequestDialog = createAction('[Requests] Opened show request dialog', props<{ request: Request }>());

export const sendRequest = createAction('[Request] Save request', props<{ image: File }>());
export const sendRequestCompleted = createAction('[Request] Save request completed', props<{ request: RequestDTO }>());
export const sendRequestFailed = createAction('[Request] Save request failed', props<{ error: any }>());

export const showRequests = createAction('[Request] Show requests', props<{ user?: User }>());

export const exportRequests = createAction('[Requests] Export Requests', props<{ userId?: number }>());
export const exportRequestsCompleted = createAction('[Requests] Export Requests Completed', props<{ data: Blob }>());
export const exportRequestsFailed = createAction('[Requests] Export Requests Failed', props<{ error: any }>());
