import { Component, Inject, OnDestroy, OnInit, Optional } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { select, Store } from "@ngrx/store";
import { Subject, map, takeUntil } from "rxjs";
import { Request } from "src/app/commons/models/request.model";
import { User } from "src/app/commons/models/user.model";
import { openReportDialog } from 'src/app/store/actions/report.actions';
import * as RequestActions from "src/app/store/actions/request.actions";
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';
import { AppState } from "src/app/store/reducers";

@Component({
  selector: "identifai-request-info",
  templateUrl: "./request-info.component.html",
  styleUrls: ["./request-info.component.scss"],
})
export class RequestInfoComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  request: Request;
  currentUser: User;

  constructor(
    private store$: Store<AppState>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.store$.select(AuthSelectors.getCurrentUser).pipe(
      takeUntil(this.unsubscribe$),
      map(dto => dto ? new User(dto) : null),
    ).subscribe(user => {
      this.currentUser = user;
    });
  }
  ngOnInit(): void {
    this.request = this.data.request;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
    this.store$.dispatch(RequestActions.closeRequestDialog());
  }

  report() {
    this.close();
    this.store$.dispatch(openReportDialog());
  }
}
