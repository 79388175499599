import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { User } from "src/app/commons/models/user.model";
import { Logo } from "src/app/constants/assets.constants";
import { logout } from "src/app/store/actions/auth.actions";
import * as SidebarActions from "src/app/store/actions/sidebar.actions";
import * as UserActions from "src/app/store/actions/user.actions";
import { AppState } from "src/app/store/reducers";
import { getCurrentUser } from "src/app/store/selectors/auth.selectors";
import { environment } from "src/environments/environment";

@Component({
  selector: "identifai-topnav",
  templateUrl: "./topnav.component.html",
  styleUrls: ["./topnav.component.scss"],
})
export class TopnavComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  url: string;
  currentUser: User;
  logo = Logo;
  beta: boolean = environment.beta;

  constructor(private store$: Store<AppState>, private router: Router) {}

  ngOnInit() {
    this.store$
      .pipe(
        select(getCurrentUser),
        takeUntil(this.unsubscribe$),
        map((dto) => (dto ? new User(dto) : null))
      )
      .subscribe((user) => {
        this.currentUser = user;
      });
  }

  toggleSidebar() {
    this.store$.dispatch(SidebarActions.sidebarToggle());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logout() {
    this.store$.dispatch(logout({ showConfirm: true }));
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector("body");
    return dom.classList.contains("push-right");
  }

  changePassword() {
    if (this.currentUser) {
      this.store$.dispatch(
        UserActions.changeUserPassword({ user: this.currentUser.toDTO() })
      );
    }
  }

  editCurrentUser() {
    if (this.currentUser) {
      this.store$.dispatch(UserActions.editCurrentUser());
    }
  }

  apiKey() {
    this.store$.dispatch(
      UserActions.openApiKeyDialog({ user: this.currentUser })
    );
  }
}
