import { LineChartComponent, ScaleType } from "@swimlane/ngx-charts";
import { curveStep } from "d3-shape";
import { colorScheme } from "./graph.constants";

export const ImageBarGraph: Partial<LineChartComponent> = {
    animations: true,
    xAxis: true,
    yAxis: true,
    showYAxisLabel: true,
    showXAxisLabel: true,
    yAxisLabel: 'Images',
    xAxisLabel: 'Users',
    timeline: false,
    legend: true,
    // curve: curveStep,
    scheme: {
        name: 'primary',
        selectable: true,
        group: ScaleType.Linear,
        domain: ['#424c65', '#18213a', '#405691', '#31416d']
    }
}