<header mat-dialog-title class="u-flex-full">
  <span>
    <span>Change password</span><span *ngIf="user"> for {{ user.name }}</span>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="passwordForm" novalidate>
    <div class="u-full-width">
      <span class="u-flex-full">
        <mat-form-field>
          <input
            type="password"
            placeholder="New password"
            matInput
            formControlName="newPass"
            required
          />

          <mat-hint
            align="end"
            matTooltip="Password:
            1. Must contain an upper case character
            2. Must contain a lower case character
            3. Must contain a number
            4. Must contain a special character
            5. Must be at least 8 characters
            6. Must not contain three or more consecutive characters"
            ><mat-icon>help</mat-icon>
          </mat-hint>

          <mat-error>
            {{ passwordForm.get("newPass")?.errors?.error || "Field required" }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="u-full-width">
          <input
            type="password"
            placeholder="Confirm password"
            matInput
            formControlName="confirmPass"
          />
          <mat-error
            *ngIf="passwordForm.controls['confirmPass'].errors?.notEquivalent"
            >Passwords do not match</mat-error
          >
        </mat-form-field>
      </span>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button
      mat-raised-button
      color="basic"
      type="reset"
      (click)="revert()"
      [disabled]="passwordForm.pristine"
    >
      Reset
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="updatePassword()"
      [disabled]="passwordForm.pristine || !passwordForm.valid"
    >
      Save
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Close
  </button>
</mat-dialog-actions>
