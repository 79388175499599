<mat-expansion-panel style="margin-bottom: 16px">
  <mat-expansion-panel-header> Filter </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <!-- TODO: if admin select user -->
      <mat-form-field *showFor="['admin']" class="u-full-width">
        <mat-label>User</mat-label>
        <identifai-user-field formControlName="user"></identifai-user-field>
        <mat-error>Field required</mat-error>
      </mat-form-field>

      <mat-radio-group aria-label="Select an option" formControlName="amount">
        <mat-radio-button value="positive"
          >Positive Transactions</mat-radio-button
        >
        <mat-radio-button value="negative"
          >Negative Transactions</mat-radio-button
        >
      </mat-radio-group>

      <span class="u-flex-full">
        <button
          class="filter"
          mat-raised-button
          [disabled]="filtersForm.pristine"
          color="primary"
          (click)="applyFilters()"
        >
          Filter
        </button>
        <button
          mat-icon-button
          *ngIf="!filtersForm.pristine"
          (click)="resetFilters()"
          matTooltip="Reset"
        >
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>
  </form>
</mat-expansion-panel>
