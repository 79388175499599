<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="user">Edit user: {{ user.name }}</span>
    <span *ngIf="!user">New user</span>
  </span>
  <span *ngIf="user">
    <ng-container>
      <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
        <!-- <button mat-menu-item (click)="apiKey()">
          <mat-icon>key</mat-icon>
          Api-Key
        </button> -->
        <button mat-menu-item (click)="changePassword()">
          <mat-icon>vpn_key</mat-icon>
          Change Password
        </button>
        <button mat-menu-item (click)="deleteUser()">
          <mat-icon>delete</mat-icon>
          Delete
        </button>
      </mat-menu>
    </ng-container>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="userForm" novalidate>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Name" formControlName="name" type="text" required />
      <mat-error>Field required</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="E-mail" formControlName="email" type="email" required />
      <mat-error>Invalid e-mail</mat-error>
    </mat-form-field>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Heuristic</mat-label>
        <mat-select formControlName="heuristic" required>
          <mat-option *ngFor="let heuristic of heuristics" [value]="heuristic">{{heuristic}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="u-flex-full" *showFor="['admin']">
      <mat-form-field class="u-full-width">
        <mat-label>Role</mat-label>
        <mat-select formControlName="role" required>
          <mat-option value="admin">{{ "admin" | userRole }}</mat-option>
          <mat-option value="client">{{ "client" | userRole }}</mat-option>
        </mat-select>
        <mat-error>Field required</mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="!user" class="u-full-width">
      <span class="u-flex-full">
        <mat-form-field>
          <input type="password" placeholder="Password" autocomplete="new-password" matInput formControlName="password"
            required />
          <mat-error>{{
            userForm.get("password")?.errors?.error || "Field required"
            }}</mat-error>
          <mat-hint align="end" matTooltip="Password:
            1. Must contain an upper case character
            2. Must contain a lower case character
            3. Must contain a number
            4. Must contain a special character
            5. Must be at least 8 characters
            6. Must not contain three or more consecutive characters"><mat-icon>help</mat-icon>
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="u-full-width">
          <input type="password" placeholder="Confirm password" matInput formControlName="confirmPass" />
          <mat-error *ngIf="userForm.controls['confirmPass'].errors?.notEquivalent">Passwords do not match</mat-error>
        </mat-form-field>
      </span>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Address</mat-label>
        <input matInput placeholder="Address" formControlName="address" />
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>City</mat-label>
        <input matInput placeholder="City" formControlName="city" />
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>ZIP Code</mat-label>
        <input matInput placeholder="ZIP Code" formControlName="zip" />
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>Country</mat-label>
        <input matInput placeholder="Country" formControlName="country" />
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Phone</mat-label>
        <input matInput placeholder="Phone" formControlName="phone" />
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>Other Contact</mat-label>
        <input matInput placeholder="Other Contact" formControlName="contact" />
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>VAT</mat-label>
        <input matInput placeholder="VAT" formControlName="vat" />
      </mat-form-field>
    </div>

    <div>
      <mat-checkbox formControlName="privacyPolicy" labelPosition="before">
        <a [href]="privacyPolicy" target="_blank">Privacy Policy</a>
      </mat-checkbox>

      <mat-checkbox *showFor="['admin']" formControlName="tos" labelPosition="before">
        <a [href]="termsOfService" target="_blank">ToS</a>
      </mat-checkbox>
    </div>
  </form>
  <!-- {{userForm.value | json}} -->
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()" [disabled]="userForm.pristine">
      Reset
    </button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="userForm.pristine || !userForm.valid">
      Save
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Close
  </button>
</mat-dialog-actions>