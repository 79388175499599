import { StackGraphBarData } from 'src/app/helpers/graph.utils';

import { Base, BaseDTO } from './base.model';
import { ClassificationType, Image, ImageDTO } from './image.model';
import { User, UserDTO } from './user.model';


export interface RequestsGraphData {
  data: StackGraphBarData[];
  total: number;
  totalArtificial: number;
  totalHuman: number;
}

export interface RequestFilters {
  user?: User;
  search?: string;
  classification?: ClassificationType;
  version?: string;
  image?: File;
}

export interface RequestGraphFilters {
  user_id?: number;
  start?: string;
  end?: string;
}

export interface RequestDTO extends BaseDTO {
  user_id: number;
  image_hash: string;
  force_classification: ClassificationType;

  image: ImageDTO;
  user: UserDTO;
  current_heuristic: string;

}

export class Request extends Base {
  userId: number;
  imageHash: string;
  forceClassification: ClassificationType;

  image: Image;
  user: User;
  currentHeuristic: string;

  constructor(source: RequestDTO) {
    super(source);
    if (source) {
      this.userId = source.user_id;
      this.imageHash = source.image_hash;
      this.forceClassification = source.force_classification;
      this.image = new Image(source.image);
      this.user = new User(source.user);
      this.currentHeuristic = source.current_heuristic;
      // this.details = {
      //   "active_manifest": "urn:uuid:eb41d7d0-78f3-4de9-9f7f-6b8cbeb64d17",
      //   "manifests": {
      //     "urn:uuid:eb41d7d0-78f3-4de9-9f7f-6b8cbeb64d17": {
      //       "claim_generator": "Microsoft_Responsible_AI/1.0",
      //       "claim_generator_info": [
      //         {
      //           "name": "Microsoft Responsible AI Image Provenance",
      //           "version": "1.0"
      //         }
      //       ],
      //       "assertions": [
      //         {
      //           "label": "c2pa.actions",
      //           "data": {
      //             "actions": [
      //               {
      //                 "action": "c2pa.created",
      //                 "softwareAgent": "Bing Image Creator",
      //                 "when": "2024-01-04T05:59:13Z"
      //               }
      //             ]
      //           }
      //         }
      //       ],
      //       "signature_info": {
      //         "issuer": "Microsoft Corporation",
      //         "cert_serial_number": "1137338005225109437724101408746055755605475347",
      //         "time": "2024-01-04T05:59:12+00:00"
      //       },
      //     }
      //   }
      // }
    }
  }

  toDTO(): RequestDTO {
    let result: RequestDTO = <RequestDTO>super.toDTO();
    result.user_id = this.userId;
    result.image_hash = this.imageHash;
    result.current_heuristic = this.currentHeuristic;
    // result.force_classification = this.forceClassification;
    return result;
  }

  get classification(): ClassificationType {
    return this.image.getClassification(this.currentHeuristic);
  }

  get confidence(): number {
    return this.image.getConfidence(this.currentHeuristic);
  }

  get falsePositive(): boolean {
    return !!this.forceClassification || !!this.image?.falsePositive;
  }

  get userName(): string {
    return this.user.name;
  }

  get createdAtDate(): string {
    let createAtTime = new Date(this.createdAt);
    createAtTime.setHours(0, 0, 0, 0);
    return createAtTime.toLocaleDateString();
  }

}
