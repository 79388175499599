<header mat-dialog-title class="u-flex-full">
  <span>
    <span>Report: {{ report.id }}</span>
  </span>
</header>

<mat-dialog-content>
  <div class="info">
    <div class="col-1">
      <div class="w-full" *ngIf="report?.image?.path">
        <img src="{{ report?.image?.path }}" height="400px" />
      </div>
    </div>

    <div class="col-2">
      <div class="w-full">
        <b>Hash:</b>
        {{ report.imageHash }}
      </div>

      <div class="w-full" *ngIf="report?.classification">
        <b>Reported Classification:</b>
        {{ report.classification | classification }}
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="u-flex-full w-full">
  <div class="u-flex-full" *ngIf="!report.solved">
    <button mat-raised-button color="basic" type="button" (click)="approve()">
      Approve
    </button>

    <button mat-raised-button color="basic" type="button" (click)="decline()">
      Reject
    </button>
  </div>

  <div class="u-flex-full">
    <button mat-raised-button color="basic" type="button" (click)="close()">
      Close
    </button>
  </div>
</mat-dialog-actions>