<div>
  <mat-card class="u-center-text">
    <mat-card-header> </mat-card-header>

    <mat-card-content>
      <div class="u-flex-full info-container">
        <div class="mat-elevation-z8">
          <span class="info-title">Total Classifications:</span>
          <span>{{ total | async }}</span>
        </div>
        <div class="mat-elevation-z8">
          <span class="info-title">Human / Artificial:</span>
          <span>
            {{ (requestGraphData | async)?.totalHuman }} /
            {{ (requestGraphData | async)?.totalArtificial }}
          </span>
        </div>
        <ng-container *ngIf="
            (total | async) -
              (requestGraphData | async)?.totalHuman -
              (requestGraphData | async)?.totalArtificial >
            0
          ">
          <div class="mat-elevation-z8" *showFor="['client']">
            <span class="info-title">Analyzing:</span>
            <span>{{
              (total | async) -
              (requestGraphData | async)?.totalHuman -
              (requestGraphData | async)?.totalArtificial
              }}</span>
          </div>
        </ng-container>
        <div class="mat-elevation-z8" *showFor="['client']">
          <span class="info-title">Balance:</span>
          <span>{{ currentUser.balance }}</span>
        </div>
      </div>
      <div class="mat-elevation-z8 mb-1 graph-container">
        <div class="graph">
          <ngx-charts-bar-vertical-stacked [results]="(requestGraphData | async)?.data"
            [legend]="currentUser.admin && !defaultFilters?.user" [showXAxisLabel]="requestGraphConfig.showXAxisLabel"
            [showYAxisLabel]="requestGraphConfig.showYAxisLabel" [xAxis]="requestGraphConfig.xAxis"
            [yAxis]="requestGraphConfig.yAxis" [xAxisLabel]="requestGraphConfig.xAxisLabel"
            [yAxisLabel]="requestGraphConfig.yAxisLabel" [scheme]="requestGraphConfig.scheme">
          </ngx-charts-bar-vertical-stacked>
        </div>
        <div class="graph" *ngIf="currentUser.admin && !defaultFilters?.user">
          <ngx-charts-bar-vertical-stacked [results]="imagesGraphData | async" [legend]="true"
            [showXAxisLabel]="imageGraphConfig.showXAxisLabel" [showYAxisLabel]="imageGraphConfig.showYAxisLabel"
            [xAxis]="imageGraphConfig.xAxis" [yAxis]="imageGraphConfig.yAxis" [xAxisLabel]="imageGraphConfig.xAxisLabel"
            [yAxisLabel]="imageGraphConfig.yAxisLabel" [scheme]="imageGraphConfig.scheme">
          </ngx-charts-bar-vertical-stacked>
        </div>
      </div>

      <div class="export-btn-container">
        <button mat-raised-button color="primary" (click)="exportRequests()">
          Download Classifications
        </button>
      </div>
      <div class="mat-elevation-z8 mb-1">
        <request-list [requests]="requests" [defaultFilters]="defaultFilters" [total]="total"
          [displayedColumns]="displayedColumns" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
          (onFilterChange)="filtersChange($event)" (onSendRequest)="sendRequest()" (onReportRequest)="reportRequest()"
          (onShowRequest)="showRequest($event)"></request-list>
      </div>

      <!-- <div class="btn-container">
        <button mat-raised-button (click)="sendRequest()" color="primary">
          Classify an image
        </button>
        <button mat-raised-button (click)="reportRequest()" color="primary">
          Report a classification
        </button>
      </div> -->
    </mat-card-content>
  </mat-card>
</div>