import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { AppState } from 'src/app/store/reducers';
import { routerGo } from 'src/app/store/actions/router.actions';
import { VERSION } from 'src/environments/version';
import * as SidebarActions from 'src/app/store/actions/sidebar.actions';
import * as SidebarSelector from 'src/app/store/selectors/sidebar.selector';


@Component({
  selector: "identifai-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  version = VERSION;
  isOpen: Observable<boolean>;

  constructor(private store$: Store<AppState>) { }

  menuClick(path: string[]) {
    this.store$.dispatch(routerGo({ path: path }));
    this.toggleSidebar();
  }

  ngOnInit() {
    this.isOpen = this.store$.pipe(select(SidebarSelector.getIsToggled), takeUntil(this.unsubscribe$));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private toggleSidebar() {
    this.store$.dispatch(SidebarActions.sidebarToggle());
  }
}