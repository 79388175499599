<header mat-dialog-title class="u-flex-full">
  <span>
    <span>Image Classification</span>
  </span>
</header>

<mat-dialog-content>
  <p>
    To achieve better classification, use JPG or JPEG images.
  </p>
  <form class="w-full">
    <mat-form-field class="file-field w-full">
      <ngx-mat-file-input [formControl]="imageCtrl" placeholder="Upload Image" [accept]="acceptsString">
        <mat-icon ngxMatFileInputIcon color="primary" style="font-size: 1.5rem">folder</mat-icon>
      </ngx-mat-file-input>
      <mat-error> {{ imageCtrl.errors?.error }}</mat-error>
      <mat-hint>The maximum allowed size is {{ maxSize | byteFormat }}</mat-hint>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="primary" (click)="send()" [disabled]="imageCtrl.invalid">
      Submit
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Close
  </button>
</mat-dialog-actions>