import { UntypedFormGroup } from '@angular/forms';
import { GraphLineData } from 'src/app/helpers/graph.utils';

import { Base, BaseDTO } from './base.model';

export type UsersGraphData = GraphLineData[];

export type Role = "admin" | "client";

export interface UserFilters {
  search?: string;
  roles?: Role[];
}

export interface UserDTO extends BaseDTO {
  email: string;
  name: string;
  password?: string;
  user_roles: Role[];
  address?: string;
  zip?: string;
  city?: string;
  country?: string;
  phone?: string;
  contact?: string;
  vat?: string;
  api_key?: string;
  privacy_policy?: boolean;
  tos?: boolean;
  balance: number;
  heuristic: string;
}

export class User extends Base {
  email: string;
  name: string;
  password?: string;
  role: Role;
  address?: string;
  zip?: string;
  city?: string;
  country?: string;
  phone?: string;
  contact?: string;
  vat?: string;
  apiKey: string;
  privacyPolicy: boolean;
  tos: boolean;
  balance: number;
  heuristic: string;

  constructor(source: UserDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.email = source.email;
      if (source.user_roles) {
        this.role = source.user_roles[0];
      }
      this.password = source.password;
      this.address = source.address;
      this.zip = source.zip;
      this.city = source.city;
      this.country = source.country;
      this.phone = source.phone;
      this.contact = source.contact;
      this.vat = source.vat;
      this.apiKey = source.api_key;
      this.privacyPolicy = source.privacy_policy;
      this.tos = source.tos;
      this.balance = source.balance;
      this.heuristic = source.heuristic;
    }
  }

  toDTO(): UserDTO {
    let result: UserDTO = <UserDTO>super.toDTO();
    result.name = this.name;
    result.email = this.email;
    result.password = this.password;
    result.user_roles = [this.role];
    result.address = this.address;
    result.zip = this.zip;
    result.city = this.city;
    result.country = this.country;
    result.phone = this.phone;
    result.contact = this.contact;
    result.vat = this.vat;
    if (this.apiKey)
      result.api_key = this.apiKey;
    result.privacy_policy = this.privacyPolicy;
    result.tos = this.tos;
    result.heuristic = this.heuristic;

    return result;
  }

  static fromFormGroup(formGroup: UntypedFormGroup, original?: User) {
    const formModel = formGroup.getRawValue();
    let user: User = new User(null);
    user.name = formModel.name;
    user.email = formModel.email;
    user.password = formModel.password;
    user.role = formModel.role;
    user.address = formModel.address;
    user.zip = formModel.zip;
    user.city = formModel.city;
    user.country = formModel.country;
    user.phone = formModel.phone;
    user.contact = formModel.contact;
    user.vat = formModel.vat;
    user.privacyPolicy = formModel.privacyPolicy;
    user.tos = formModel.tos;
    user.heuristic = formModel.heuristic;

    user.id = original ? original.id : null;

    return user;
  }

  get admin(): boolean {
    return this.hasRole("admin");
  }

  get client(): boolean {
    return this.hasRole("client");
  }

  hasRole(role: Role): boolean {
    return this.role && this.role === role;
  }
}
