import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ImagesGraphData } from 'src/app/commons/models/image.model';
import { Request, RequestFilters, RequestsGraphData } from 'src/app/commons/models/request.model';
import { User } from 'src/app/commons/models/user.model';
import { ImageBarGraph } from 'src/app/constants/image.constants';
import { RequestBarGraph } from 'src/app/constants/request.constants';
import { BIG_PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as ImageActions from 'src/app/store/actions/image.actions';
import * as ReportActions from 'src/app/store/actions/report.actions';
import * as RequestActions from 'src/app/store/actions/request.actions';
import { AppState } from 'src/app/store/reducers';
import { getCurrentUser } from 'src/app/store/selectors/auth.selectors';
import { getImageGraphData } from 'src/app/store/selectors/image.selectors';
import * as RequestSelectors from 'src/app/store/selectors/request.selectors';

import { RequestColumn } from '../request-list/request-list.component';


@Component({
  selector: 'identifai-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  requests: Observable<Request[]>;
  filters: Observable<RequestFilters>;
  total: Observable<number>;

  currentUser: User;

  defaultFilters: RequestFilters;
  pageSizeOptions = BIG_PAGE_SIZE_OPTIONS;

  requestGraphData: Observable<RequestsGraphData>;
  imagesGraphData: Observable<ImagesGraphData>;
  requestGraphConfig = RequestBarGraph;
  imageGraphConfig = ImageBarGraph;

  @Input()
  displayedColumns: RequestColumn[] = ["name", "date_time", "classification", "confidence", "status"];

  constructor(private store$: Store<AppState>) {
    this.store$.select(getCurrentUser).pipe(
      takeUntil(this.unsubscribe$),
      map(user => new User(user))
    ).subscribe((user) => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.requests = this.store$.pipe(
      select(RequestSelectors.getRequests),
      takeUntil(this.unsubscribe$),
      map(dtos => dtos ? dtos.map(dto => new Request(dto)) : null));
    this.total = this.store$.pipe(select(RequestSelectors.getTotalRequests), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(RequestSelectors.getFilters), takeUntil(this.unsubscribe$));

    this.requestGraphData = this.store$.pipe(select(RequestSelectors.getRequestGraphData), takeUntil(this.unsubscribe$));
    this.imagesGraphData = this.store$.pipe(select(getImageGraphData), takeUntil(this.unsubscribe$));

    this.filters.subscribe(filters => this.defaultFilters = filters);

    this.load();
  }

  load() {
    this.store$.dispatch(RequestActions.loadRequests(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters, includes: [] }
    ));

    this.store$.dispatch(RequestActions.loadRequestsGraph());

    this.store$.dispatch(ImageActions.loadImagesGraph());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(RequestActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(RequestActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: RequestFilters) {
    this.store$.dispatch(RequestActions.changeFilters({ filters }));
  }

  sendRequest() {
    this.store$.dispatch(RequestActions.openRequestDialog());
  }

  reportRequest() {
    this.store$.dispatch(ReportActions.openReportDialog());
  }

  showRequest(request: Request) {
    if (request) {
      this.store$.dispatch(RequestActions.showRequestDialog({ request }));
    }
  }

  exportRequests() {
    this.store$.dispatch(RequestActions.exportRequests({}));
  }
}
