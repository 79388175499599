<div id="sidebar" *ngIf="isOpen | async">
  <mat-nav-list>
    <ng-container *showFor="['client']">
      <div mat-subheader>Home</div>

      <a mat-list-item (click)="menuClick(['/'])">
        <mat-icon>home</mat-icon>
        <span>IdentifAI Control Panel</span>
      </a>
      <mat-divider></mat-divider>
    </ng-container>

    <ng-container *showFor="['admin']">
      <div mat-subheader>Administration</div>

      <a mat-list-item (click)="menuClick(['/users'])">
        <mat-icon>people</mat-icon>Users
      </a>

      <a mat-list-item (click)="menuClick(['/reports'])">
        <mat-icon>report</mat-icon>Report management
      </a>

      <a mat-list-item (click)="menuClick(['/requests'])">
        <mat-icon>format_list_bulleted</mat-icon>Classifications
      </a>

      <a mat-list-item (click)="menuClick(['/graphs'])">
        <mat-icon>bar_chart</mat-icon>Usage
      </a>
      <mat-divider></mat-divider>
    </ng-container>

    <ng-container>
      <div mat-subheader>Other</div>

      <a mat-list-item (click)="menuClick(['/credits'])">
        <mat-icon>account_balance_wallet</mat-icon>Credit
      </a>

      <a mat-list-item (click)="menuClick(['/hash'])">
        <mat-icon>tag</mat-icon>Hash Calc
      </a>
    </ng-container>
  </mat-nav-list>
  <div class="footer">
    <mat-divider></mat-divider>
    <div class="version">V.{{ version.version }}-{{ version.raw }}</div>
    <div>Proudly made in Italy</div>
    <img src="assets/images/italy.png" alt="sn-logo" height="30px" />
  </div>
</div>