<mat-toolbar color="primary" class="fix-nav">
  <button type="button" mat-icon-button (click)="toggleSidebar()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <span class="page-title">
    <img class="logo" src="{{ logo }}" />
    <span *ngIf="beta" class="beta_badge">BETA</span>
  </span>
  <span>
    <ng-container *ngIf="currentUser">
      <button mat-icon-button [matMenuTriggerFor]="profile">
        <mat-icon>account_circle</mat-icon>
      </button>
      <mat-menu #profile="matMenu">
        <div class="text-menu-item">{{ currentUser?.name }}<br /></div>
        <mat-divider></mat-divider>

        <button mat-menu-item (click)="editCurrentUser()">
          <mat-icon>edit</mat-icon>
          Profile
        </button>

        <button mat-menu-item (click)="apiKey()">
          <mat-icon>key</mat-icon>
          Api-Key
        </button>

        <button mat-menu-item (click)="changePassword()">
          <mat-icon>vpn_key</mat-icon>
          Change Password
        </button>

        <mat-divider></mat-divider>

        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          Logout
        </button>
      </mat-menu>
    </ng-container>
  </span>
</mat-toolbar>
