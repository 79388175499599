import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';

import { selectAuthState, selectRequestState } from '../reducers';
import { AuthState } from '../reducers/auth.reducers';
import { RequestState } from '../reducers/request.reducer';

// User Selectors

export const getRequestsTableState = createSelector(
  selectRequestState,
  (state: RequestState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getRequests = createSelector(
  selectRequestState,
  selectAuthState,
  (state: RequestState, authState: AuthState) => {
    return state.list.map(request => {
      const r = copy(request);
      r.current_heuristic = authState.currentUser.heuristic
      return r;
    })
  }
);

export const getTotalRequests = createSelector(
  getRequestsTableState,
  (tableState) => tableState.total
);

export const getRequestsCurrentPage = createSelector(
  getRequestsTableState,
  (tableState) => tableState.currentPage
);

export const getRequestsPerPage = createSelector(
  getRequestsTableState,
  (tableState) => tableState.perPage
);

export const getRequestsOrder = createSelector(
  getRequestsTableState,
  (tableState) => tableState.order
);

export const getRequestsDirection = createSelector(
  getRequestsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getRequestsTableState,
  (tableState) => tableState.filters
);

export const getRequestDialogId = createSelector(
  selectRequestState,
  (state: RequestState) => state.dialogId
);

export const getRequestGraphData = createSelector(
  selectRequestState,
  (state: RequestState) => state.graphData
)
