import { createAction, props } from '@ngrx/store';

import { Report, ReportDTO, ReportFilters, ReportGraphData } from 'src/app/commons/models/report.model';
import { ClassificationType } from 'src/app/commons/models/image.model';
import { User } from 'src/app/commons/models/user.model';

export const loadReports = createAction('[Reports] Load reports', props<{ page?: number, perPage?: number, order?: string, direction?: string, filters?: ReportFilters, includes?: string[] }>());
export const loadReportsCompleted = createAction('[Reports] Load reports Completed', props<{ reports: ReportDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ReportFilters, includes?: string[] }>());
export const loadReportsFailed = createAction('[Reports] Load reports Failed', props<{ error: any }>());

export const loadReportsGraph = createAction('[Reports] Load reports graph');
export const loadReportsGraphCompleted = createAction('[Reports] Load reports graph Completed', props<{ result: ReportGraphData }>());
export const loadReportsGraphFailed = createAction('[Reports] Load reports graph Failed', props<{ error: any }>());

export const changePage = createAction('[Reports] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Reports] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Reports] Change filters', props<{ filters: ReportFilters }>());

export const openReportDialog = createAction('[Report] Opened dialog');
export const showReportDialog = createAction('[Report] Show report dialog', props<{ report: Report }>());
export const reportDialogOpened = createAction('[Report] Detail dialog opened', props<{ dialogId: string }>());
export const closeReportDialog = createAction('[Report] Close detail dialog');

export const sendReport = createAction('[Report] Save report', props<{ image: File, classification: ClassificationType }>());
export const sendReportCompleted = createAction('[Report] Save report completed', props<{ report: ReportDTO }>());
export const sendReportFailed = createAction('[Report] Save report failed', props<{ error: any }>());

export const approveReport = createAction('[Report] Approve report', props<{ id: number }>());
export const declineReport = createAction('[Report] Decline report', props<{ id: number }>());
export const reportStatusCompleted = createAction('[Report] Status report completed', props<{ report: ReportDTO }>());
export const statusReportFailed = createAction('[Report] Save report failed', props<{ error: any }>());

export const showReports = createAction('[Reports] Show reports', props<{ user?: User }>());